import React from "react"
import { graphql } from "gatsby"
import TracksTable from "../components/TracksTable"
import TrackSheet from "../components/TrackSheet"
import withLocation from "../utils/withLocation"
import LibraryPageLayout from "../components/LibraryPageLayout"
import { getTracks, QueryShape } from "../models/tracks"
import queryString from "query-string"
import GenreCards from "../components/GenreCards"

type Props = {
  data: { tracks: QueryShape }
  location: {
    search: string
  }
}

const Library: React.FC<Props> = ({ data, location }) => {
  const tracksData = getTracks(data.tracks)
  const description = `Music from the library of Dan Koch`

  let sharedTrack
  if (location.search) {
    const queriedTrackId = queryString.parse(location.search).track
    sharedTrack = tracksData.filter(obj => {
      return obj.id == queriedTrackId
    })[0]
  }

  return (
    <div className="library-container">
      <div className="modal-container">{sharedTrack && <TrackSheet track={sharedTrack} />}</div>
      <LibraryPageLayout tracksData={tracksData} title="Music Library" description={description}>
        <TracksTable placeholder={<GenreCards />} data={tracksData} />
      </LibraryPageLayout>
    </div>
  )
}

export default withLocation(Library)

export const pageQuery = graphql`
  query TracksQuery {
    tracks: allAirtable(filter: { table: { eq: "Tracks" }, data: { Has_Parent: { eq: 0 } } }) {
      edges {
        node {
          data {
            Track_Title
            Parent {
              id
            }
            Has_Parent
            Genres {
              data {
                Genre_Name
              }
              id
            }
            Vibes {
              data {
                Vibe_Name
              }
              id
            }
            Energy {
              data {
                Energy_Name
              }
              id
            }
            URL
            Length
            Priority
          }
          id
        }
      }
    }
  }
`
